@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Gayathri:wght@100;400;700&family=Inter:wght@100..900&family=Radio+Canada+Big:wght@400..700&family=Roboto:ital,wght@0,100..900;1,100..900&family=Roboto+Mono:wght@100..700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, "Inter", "Radio Canada Big", sans-serif, "Roboto Mono", monospace;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#riyas{
  background-color: yellow !important;
}